// Colors V2

import { green } from "@mui/material/colors";
import { warn } from "console";

const colors = {
  // Base
  white: "#FFFFFF",
  black: "#000000",
  transparent: "#FFFFFF00",
  // Gray (Light Mode)
  grayLM25: "#FBFCFF",
  grayLM50: "#F9FAFB",
  grayLM100: "#F2F4F7",
  grayLM200: "#EAECF0",
  grayLM250: "#EAECF5",
  grayLM300: "#D0D5DD",
  grayLM400: "#98A2B3",
  grayLM500: "#667085",
  grayLM600: "#475467",
  grayLM700: "#344054",
  grayLM800: "#182230",
  grayLM900: "#101828",
  grayLM950: "#0C111D",
  // Gray (Dark Mode)
  grayDM25: "#FAFAFA",
  grayDM50: "#F5F5F6",
  grayDM100: "#F0F1F1",
  grayDM200: "#ECECED",
  grayDM300: "#CECFD2",
  grayDM400: "#94969C",
  grayDM500: "#85888E",
  grayDM600: "#61646C",
  grayDM700: "#333741",
  grayDM800: "#1F242F",
  grayDM900: "#161B26",
  grayDM950: "#0C111D",
  // Gray - Blue
  grayBlue200: "#D5D9EB",
  // Aserta
  asertaOrange: "#F9A11B",
  asertaBlue: "#2E3C6F",
  asertaSand: "#B0A898",
  asertaBlueGray: "#566680",
  // Brand
  brand500: "#F98207",
  // Error
  error500: "#F04438",
  error600: "#D92D20",
  // Warning
  warning50: "#FFFAEB",
  warning100: "#FEF0C7",
  warning200: "#FEDF89",
  warning500: "#F79009",
  warning600: "#FDB022",
  warning700: "#B54708",
  // Success
  success500: "#17B26A",
  success700: "#067647",
  // Green
  green1: "#ABEFC6",
  green2: "#ECFDF3",
  green3: "#067647",
  green4: "#079455",
  // Blue
  "blue-1": "#102147",
  "blue-2": "#F9F4FB",
  blue3: "#B2DDFF",
  blue4: "#EFF8FF",
  blue5: "#175CD3",
  // Red
  red1: "#FECDCA",
  red2: "#FEF3F2",
  red3: "#B42318",
  red4: "#B73E06",
} as const;
export type Color = (typeof colors)[keyof typeof colors];
export default colors;
