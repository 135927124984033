import UITextProps from "./UITextProps";
import colors from "@/utils/colorsV2";
import { Typography, styled } from "@mui/material";
import { FunctionComponent } from "react";
import {
  sfUIDisplayBold,
  sfUIDisplayLight,
  sfUIDisplayMedium,
  sfUIDisplaySemiBold,
  sfUIDisplayUltraLight,
} from "@/utils/fonts";
import Link from "next/link";
import { orange } from "@mui/material/colors";

const defaultColor = "primary";
const defaultSize = "text-md";
const defaultWeight = "medium";
const defaultDisabled = false;
const defaultTransparency = "40px";

const colorStyles = (disabled: boolean) => {
  const transparency = disabled ? defaultTransparency : "";
  return {
    primary: {
      color: `${colors.asertaBlue}${transparency}`,
    },
    secondary: {
      color: `${colors.asertaBlueGray}${transparency}`,
    },
    info: {
      color: `${colors.grayDM500}${transparency}`,
    },
    warning: {
      color: `${colors.warning500}${transparency}`,
    },
    success: {
      color: `${colors.success500}${transparency}`,
    },
    success4:{
      color: `${colors.green4}${transparency}`,
    },
    error: {
      color: `${colors.error500}${transparency}`,
    },
    white: {
      color: `${colors.white}${transparency}`,
    },
    black: {
      color: `${colors.black}${transparency}`,
    },
    transparent: {
      color: `${colors.white}${transparency}`,
    },
    gray: {
      color: `${colors.grayLM800}${transparency}`,
    },
    gray600: {
      color: `${colors.grayLM600}${transparency}`,
    },
    darkGray: {
      color: `${colors.grayLM900}${transparency}`,
    },
    orange: {
      color: `${colors.asertaOrange}${transparency}`,
    },
    blue: {
      color: `${colors.asertaBlue}${transparency}`,
    },
    blue5: {
      color: `${colors.blue5}${transparency}`,
    },
    blueGray: {
      color: `${colors.asertaBlueGray}${transparency}`,
    },
    red3: {
      color: `${colors.red3}${transparency}`,
    },
    outlineOrange: {
      color: `${colors.asertaOrange}${transparency}`,
    },
    red4: {
      color: `${colors.red4}${transparency}`,
    },
    torange: {
      color: `${colors.asertaOrange}${transparency}`,
    },
    transparentWhite: {
      color: `${colors.white}${transparency}`,
    },
  };
};

const sizeStyles = {
  "text-xs": {
    fontSize: "12px",
    lineHeight: "18px",
  },
  "text-s": {
    fontSize: "14px",
    lineHeight: "20px",
  },
  "text-md": {
    fontSize: "16px",
    lineHeight: "24px",
  },
  "text-lg": {
    fontSize: "18px",
    lineHeight: "28px",
  },
  "text-xl": {
    fontSize: "20px",
    lineHeight: "30px",
  },
  "text-2xl": {
    fontSize: "24px",
    lineHeight: "32px",
  },
  "display-sm": {
    fontSize: "30px",
    lineHeight: "38px",
  },
  "display-md": {
    fontSize: "36px",
    lineHeight: "44px",
  },
  "display-lg": {
    fontSize: "48px",
    lineHeight: "60px",
  },
  "display-xl": {
    fontSize: "60px",
    lineHeight: "72px",
  },
  "display-xxl": {
    fontSize: "72px",
    lineHeight: "90px",
  },
};

const weightStyles = {
  xlight: {
    fontWeight: 300,
    ...sfUIDisplayUltraLight.style,
  },
  light: {
    fontWeight: 400,
    ...sfUIDisplayLight.style,
  },
  medium: {
    fontWeight: 500,
    ...sfUIDisplayMedium.style,
  },
  semibold: {
    fontWeight: 600,
    ...sfUIDisplaySemiBold.style,
  },
  bold: {
    fontWeight: 800,
    ...sfUIDisplayBold.style,
  },
};

const _UIText = styled(Typography)<UITextProps>(
  ({
    color = defaultColor,
    size = defaultSize,
    weight = defaultWeight,
    disabled = defaultDisabled,
  }) => {
    const colorStyle = colorStyles(disabled)[color];
    const sizeStyle = sizeStyles[size];
    const weightStyle = weightStyles[weight];

    return {
      //...sfUIDisplayMedium.style,
      ...colorStyle,
      ...sizeStyle,
      ...weightStyle,
    };
  }
);

const UIText: FunctionComponent<UITextProps> = ({
  text,
  isHtml,
  link,
  ...props
}) => {
  if (isHtml) {
    const htmlProps = {
      dangerouslySetInnerHTML: {
        __html: text as string,
      },
      ...props,
    };
    return <_UIText {...htmlProps} />;
  }

  const label = <_UIText {...props}>{!isHtml && text}</_UIText>;

  if (link) return <Link href={link}>{label}</Link>;
  return label;
};

export default UIText;
